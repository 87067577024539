.standings {
  display: flex;
  max-width: 832px;
  margin: 0 auto 30px;
  justify-content: space-evenly;
  @media (max-width: 600px) {
    justify-content: space-between;
  }
  table {
    margin: 0;
    th {
      padding-bottom: 10px;
    }
    td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .crown-container {
    position: relative;
    width: 30px;
    svg {
      position: absolute;
      top: -18px;
      right: 0;
    }
  }
}
