/* The switch - the box around the slider */
.switch {
  &er {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 23px;
    margin-left: 3px;
    margin-right: 3px;
  }

  /* Hide default HTML checkbox */
  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 26%;
    left: 0;
    right: 0;
    bottom: 25%;
    background: #888;
    transition: .4s;
    border-radius: 15px;
    &:before {
      position: absolute;
      content: "";
      height: 19px;
      width: 19px;
      left: 0;
      bottom: -4px;
      background: #fff;
      transition: .4s;
      border-radius: 50%;
    }
  }

  &__input:checked + &__slider {
    &:before {
      background: $blue;
      transform: translateX(26px);
      box-shadow: 0 0 10px rgba(255, 255, 255, .5)
    }
  }

  &__label {
    cursor: pointer;
    padding-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }

}
