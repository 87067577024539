button,
.button {
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-family: bold-cond, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  background: $blue;
  color: white;
  &[disabled] {
    opacity: .5;
  }
  &--lock-picks {
    clear: both;
    display: inline-flex;
  }
}
