select {
  padding: 3px 10px 3px 20px;
  border-width: 0 0 5px 0;
  color: $blue;
  background: transparent;
  font-size: 50px;
  cursor: pointer;
  @media (max-width: 600px) {
    padding: 10px 5px 10px 15px;
    font-size: 40px;
  }
  option {
    font-size: 50%;
    background: white;
    color: black!important;
  }
}
