.team-card {
  cursor: pointer;
  .is-picks-locked & {
    cursor: default;
  }
  &-wrapper + &-wrapper {
    margin-left: 60px;
  }
  h3 {
    color: white;
    font-size: 200%;
    transition: all .2s;
    margin: 0 0 10px;
  }
  .picked .h3-content {
    color: #00adea;
    text-shadow: 0 0 20px #999;
  }
  .eyebrow {
    display: block;
    font: 50% wide, sans-serif;
    opacity: .7;
  }
  &__picked-by-user {
    display: inline-block;
    margin: 5px;
    opacity: .7;
    text-transform: none;
  }
  &__picked-by-user--current-user {
    opacity: 1;
  }
  @media (max-width: 600px) {
    h3 {
      font-size: 20px;
    }
    &__picked-by-user {
      font-size: 18px;
      margin: 5px 3px 0;
    }
  }
}
