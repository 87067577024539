@font-face {
  font-family: "wide";
  src: url("https://res.cloudinary.com/kof/raw/upload/nfl/fonts/bold.woff") format("woff");
}
@font-face {
  font-family: "bold-cond";
  src: url("https://res.cloudinary.com/kof/raw/upload/nfl/fonts/bold-cond.woff") format("woff");
}
@font-face {
  font-family: "medium-cond";
  src: url("https://res.cloudinary.com/kof/raw/upload/nfl/fonts/medium-cond.woff") format("woff");
}
