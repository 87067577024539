.dialog {
  display: none;
  &--open {
    display: block;
    color: #333;
    background: white;
    width: 600px;
    padding: 50px;
    position: fixed;
    max-width: 90vw;
    top: 20vw;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
  }
}
