@import "fonts";

$blue: #00adea;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #444;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

a {
  color: $blue;
}

@media (min-width: 601px) {
  .mobile-only {
    display: none;
  }
}
@media (max-width: 600px) {
  .mobile-hidden {
    display: none;
  }
}

@import "page";
@import "profile";
@import "season";
@import "standings";
@import "team-card";
@import "dialog";
@import "switch";
@import "button";
@import "select";
@import "checkbox";
