.season {

  select {
    margin: 0 0 50px;
    font-family: bold-cond, Helvetica, Arial, sans-serif;
  }

  h1 {
    font-family: bold-cond, sans-serif;
    font-size: 50px;
  }

  h1, h2 {
    font-weight: normal;
    color: white;
  }

  .update-scores {
    &-container {
      font-size: 14px;
    }
    &-link {
      display: inline-block;
      position: relative;
      margin: 0 10px;
      cursor: pointer;
      &.loading {
        color: gray;
        cursor: not-allowed;
        &:before {
          content: '';
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          right: -30px;
          width: 20px;
          height: 20px;
          margin-top: -10px;
          margin-left: -10px;
          border-radius: 50%;
          border: 2px solid #ccc;
          border-top-color: #333;
          animation: spinner .6s linear infinite;
        }
      }
    }
  }

  .tabpanel {
    overflow-x: hidden;
  }

  .games {
    list-style-type: none;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    transform-origin: top left;
    &.blur {
      display: none;
    }
  }
  .game {
    display: flex;
    margin: 0;
    padding: 0 0 40px;
    justify-content: center;
    align-items: flex-start;
    max-height: 200px;
    transition: all .3s;
    overflow: hidden;
  }
  .is-game-irrelevant {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
  .in-progress {
    .score {
      animation: 3s breathe linear infinite;
    }
  }
  .date-header {
    display: block;
    margin: 0 0 20px;
    &__day {
      display: block;
      margin: 0 0 30px;
      padding: 10px 0;
      border-bottom: 1px solid #aaa;
    }
    &.is-same-day {
      .date-header__day {
        display: none;
      }
    }
  }

  .team-card {
    width: 315px;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: right;
    &__image {
      width: 100px;
      height: auto;
    }
  }
  .team-card-wrapper {
    text-align: right;
    & ~ .team-card-wrapper {
      text-align: left;
      .team-card {
        flex-direction: row;
        text-align: left;
      }
    }
  }

  .score {
    color: #999;
    font-size: 60px;
    width: 70px;
    text-align: center;
    padding: 10px 0 0;
  }

  .score[data-is-winner="true"] {
    color: #00adea;
    text-shadow: 0 0 20px #999;
  }

  .must-have-all-picks-notice {
    padding-top: 30px;
  }

  .tie-breakers__actual-yards {
    color: #00adea;
  }

  table {
    margin: 10px auto;
  }
  td {
    text-align: left;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 40px;
    }
    .team-card {
      width: auto;
      display: block;
      &.game-has-pick:not(.picked) {
        opacity: .7;
      }
      &__image {
        width: 106px;
        height: auto;
      }
    }
    .score {
      font-size: 40px;
      padding-top: 58px;
      width: auto;
      min-width: 50px;
      flex-grow: 1000;
      flex-basis: 30%;
    }
  }

  .actions {
    border-top: 5px solid #aaa;
    margin: 30px 0 0;
    padding: 30px 0 0;
  }

}

@keyframes breathe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
