/* The checkbox */
$width: 50px;
$mobileWidth: 22px;

.checkbox {
  display: inline-flex;
  position: relative;
  margin: 0 20px 10px;
  cursor: pointer;
  font-size: 22px;
  border: 3px solid #000;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 600px) {
    margin: 0 10px 10px;
    & + span {
      display: none!important;
    }
  }
}

/* Hide the browser's default checkbox */
.checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  top: 0;
  left: 0;
  width: $width;
  height: $width;
  background: #eee;
  border-radius: 8px;
  @media (max-width: 600px) {
    width: $mobileWidth;
    height: $mobileWidth;
  }
}

/* On mouse-over, add a grey background color */
.checkbox:hover .checkbox__input ~ .checkmark {
  background: #ddd;
}

/* When the checkbox is checked, add a blue background */
.checkbox .checkbox__input:checked ~ .checkmark {
  background: $blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox .checkbox__input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: $width * 9px / 25px;
  top: $width / 5;
  width: $width / 5;
  height: $width * .4;
  border: solid white;
  border-width: 0 ($width * 3px / 25px) ($width * 3px / 25px) 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  @media (max-width: 600px) {
    left: $mobileWidth * 9px / 25px;
    top: $mobileWidth / 5;
    width: $mobileWidth / 5;
    height: $mobileWidth * .4;
    border-width: 0 ($mobileWidth * 3px / 25px) ($mobileWidth * 3px / 25px) 0;
  }
}
