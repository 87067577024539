.page {
  text-align: center;
  padding: 20px 20px 0;
  background: #444;
  font: 150%/1 medium-cond, Helvetica, Arial;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: white;
}

.page-header {
  margin: -20px -20px 20px;
  padding: 15px 15px;
  background: $blue;
  color: white;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 832px;
    margin: 0 auto;
  }
  a {
    color: white;
    cursor: pointer;
  }
  u {
    cursor: pointer;
  }
  .logo {
    fill: white; //saddlebrown
    cursor: pointer;
    width: 35px;
    height: 35px;
    vertical-align: middle;
    margin: 0 5px 0 0;
  }
}


.page-footer {
  padding: 20px;
  margin: 50px -20px 0;
  background: #333;
  color: #aaa;
}
